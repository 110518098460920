import React, {useState, useEffect, useContext, Fragment} from 'react'

import './DoctorsPanel.scss'
import useApi from '../../../hooks/useApi'
import ErrorsContext from "../../../ErrorsContext";
import {dedupeDoctors} from "../MerchantReport"
import DoctorReviewDetails from './doctor-panel/DoctorReviewDetails';
import toast from 'react-hot-toast'
import appConfig from "../../../app-config.json";



export default function Doctors({baseUrl, merchantReport, newDoctors, refreshDoctors, printVersion}){
    const { apiPost, apiGet} = useApi()
    const { setError } = useContext(ErrorsContext)
    const [reviewActive, setReviewActive] = useState([]);
    const [licenseActive, setLicenseActive] = useState([]);
    const [isExpanded, setIsExpanded] = useState([]);
    const [licenseVerificationEnabled, setLicenseVerificationEnabled] = useState(false)
    const [awaitingSubmission, setAwaitingSubmission] = useState(false)
    const [isInitialPrintState, setInitialPrintState] = useState(false)


    useEffect(() => {
        apiGet(`lender/license-verification-enabled`).then(data => {
            if ('license_verification_enabled' in data){
                setLicenseVerificationEnabled(data.license_verification_enabled)
            }
        }).catch( response => {
            console.log(response)
        })
    }, [])

    useEffect(() => {
        if (isInitialPrintState) {
            toggleAll('all')
        }
    }, [newDoctors])



    function convertStateAbbrToFullName(abbr) {
        const states = {
            AL: "Alabama",AK: "Alaska",AZ: "Arizona",AR: "Arkansas",CA: "California",CO: "Colorado",CT: "Connecticut",
            DE: "Delaware",FL: "Florida",GA: "Georgia",HI: "Hawaii",ID: "Idaho",IL: "Illinois",IN: "Indiana",IA: "Iowa",
            KS: "Kansas",KY: "Kentucky",LA: "Louisiana",ME: "Maine",MD: "Maryland",MA: "Massachusetts",MI: "Michigan",
            MN: "Minnesota",MS: "Mississippi",MO: "Missouri",MT: "Montana",NE: "Nebraska",NV: "Nevada",NH: "New+Hampshire",
            NJ: "New+Jersey",NM: "New+Mexico",NY: "New+York",NC: "North+Carolina",ND: "North+Dakota",OH: "Ohio",
            OK: "Oklahoma",OR: "Oregon",PA: "Pennsylvania",RI: "Rhode+Island",SC: "South+Carolina",SD: "South+Dakota",
            TN: "Tennessee",TX: "Texas",UT: "Utah",VT: "Vermont",VA: "Virginia",WA: "Washington",WV: "West+Virginia",
            WI: "Wisconsin",WY: "Wyoming"
        };
    
        abbr = abbr.toUpperCase(); // Ensure abbreviation is uppercase
    
        if (states.hasOwnProperty(abbr)) {
            return states[abbr]; // Return the full state name if abbreviation is valid
        } else {
            return "Unknown State"; // Return a default message for invalid abbreviations
        }
    }


    function replaceEmptyStringsWithNull(obj) {
        for (const key in obj) {
            if (obj[key] === '') {
                obj[key] = null;
            } 
        }
        return obj;
    }


    function submitLicenseInquiry(licenseInquiry){
        let tempLicenseInquiry = { ...licenseInquiry };
        tempLicenseInquiry.merchant_id = merchantReport.merchant.id
        // Regular expression pattern to match the first instance of a UUID
        const uuidPattern = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/;
        tempLicenseInquiry = replaceEmptyStringsWithNull(tempLicenseInquiry)
        setAwaitingSubmission(true)
        apiPost('merchant/submit-license-verification-order', tempLicenseInquiry)
            .then(data => { 
                if (!!data.status){
                    toast('License Verification Submitted for ' + "'" + licenseInquiry.licensee_name + "'")
                }else{
                    toast('License Verification Submission Failed')
                }
            }).finally(() => {
                setAwaitingSubmission(false)
            });
    }


    function handleLicenseSubmission(license){
        var isLicenseFormValid = true

        if(license?.verifications?.length != 0 && !isOlderThanSixMonths(license?.verifications[0].updated_at)){
            toast('License Verification is up to date for ' + "'" + license.name + "'")
            isLicenseFormValid = false
        }

        let temp_license = {
            merchant_id: '',
            npi_id: license?.npi_id,
            licensee_name: license?.name,
            licensee_addr1: '', licensee_city: '', licensee_state: license?.license_number_state_code, licensee_zip5: '',
            licensee_id: license?.license_number, licensee_category: 'dentistry', licensee_subcategory: 'otherDentistry'
        }

        if (isLicenseFormValid) {
            submitLicenseInquiry(temp_license)
        }

        refreshDoctors()
    }


    const isOlderThanSixMonths = (updatedAt) => {
        const updatedDate = new Date(updatedAt);
        const currentDate = new Date();
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
        return updatedDate < sixMonthsAgo;
      };


    function toggle(id, dropdown){

        if(dropdown === "review"){
            if(reviewActive.includes(id)){
                setReviewActive(reviewActive.filter(e => e !== id))
                setLicenseActive(licenseActive.filter(e => e !== id))
            }
            else{
                setLicenseActive(licenseActive.filter(e => e !== id))
                setReviewActive([...reviewActive, id])
            }
        }else if(dropdown === "license"){
            if(licenseActive.includes(id)){
                setReviewActive(reviewActive.filter(e => e !== id))
                setLicenseActive(licenseActive.filter(e => e !== id))
            }
            else{
                setReviewActive(reviewActive.filter(e => e !== id))
                setLicenseActive([...licenseActive, id])
            }
        }
    }

    function expand(id){
        // call api with doctor_id from the reviews to grab doctor reviews for that doctor. 
        if(isExpanded.includes(id)){
            setIsExpanded(isExpanded.filter(e => e !== id))
        }
        else{
            setIsExpanded([...isExpanded, id])
        }
    }

    // expand(`${p.id}_${p.review_summary[index].alias}

    function expandAll() {
        let temp = []
        dedupeDoctors(newDoctors).forEach(d => {
            let id = d.id
            d.review_summary.forEach(review => {
                let alias = review.alias
                temp.push(id + '_' + alias)
            })
        })

        setIsExpanded(temp)
    }

    function toggleAll(dropdown){
        let temp = []
        dedupeDoctors(newDoctors).forEach(d => {
            temp.push(d.id)
        })

        if(dropdown === "review"){
            if(reviewActive.length === dedupeDoctors(newDoctors).length){
                setLicenseActive([])
                setReviewActive([])
            }
            else{
                setLicenseActive([])
                setReviewActive(temp)
            }
        }else if(dropdown === "license"){
            if(licenseActive.length === dedupeDoctors(newDoctors).length){
                setLicenseActive([])
                setReviewActive([])
            }
            else{
                setReviewActive([])
                setLicenseActive(temp)
            }
        } else if (dropdown === "all") {
            setReviewActive(temp)
            setLicenseActive(temp)
            setInitialPrintState(true)
            expandAll()
        }
    }

    function openAllPrintVersion() {
        let temp = []
        dedupeDoctors(newDoctors).forEach(d => {
            temp.push(d.id)
        })
        setLicenseActive(temp)
        setReviewActive(temp)
    }

    function serviceLineLink(service, doctor){
        return `https://www.google.com/search?q=${[
                service,
                doctor
            ].filter(Boolean).map(encodeURIComponent).join('+')}`;
    }

    function handleRenderComplete(){
        // console.log("Render is complete!");
    }

    if (!merchantReport) return <div>No Report Data</div>

    return (
        <div className="box1">
            <h3 className="header">
                <span className='doctor-name'>Doctors Overview</span>
                <button onClick={() => toggleAll("license")}>
                    {licenseActive.length === dedupeDoctors(newDoctors).length ? 'Close All Licenses' : 'Open All License'}
                </button>
                <button onClick={() => toggleAll("review")}>
                    {reviewActive.length === dedupeDoctors(newDoctors).length ? 'Close All Reviews' : 'Open All Reviews'}
                </button>
            </h3>
            <div className="doctors-section">
                <table>
                    <thead></thead>
                    <tr></tr>
                </table>
                {printVersion && !isInitialPrintState && toggleAll('all')}
                {dedupeDoctors(newDoctors)?.map((p, index) => 
                    <div className='doctor'>
                        <span className='doctor-header'>
                            <h2 className='doctor-name'><strong>{p.name}</strong></h2>
                            <span className='dropdown' onClick={() => toggle(p.id, "license")}>License Details<i className={`fa ${licenseActive.includes(p.id) ? 'fa-chevron-up' : 'fa-chevron-down'}`} aria-hidden="true"/></span>
                            <span className='dropdown' onClick={() => toggle(p.id, "review")}>Review Details<i className={`fa ${reviewActive.includes(p.id) ? 'fa-chevron-up' : 'fa-chevron-down'}`} aria-hidden="true" /></span>
                        </span>
                        <span>{p.specialty}</span>
                        {licenseActive.includes(p.id) && (
                            <div key={index} className="doctor-licenses-container">
                                {p.doctor_licenses?.length === 0 ? (
                                    <div className="doctor-licenses-details">
                                        <div className="items">
                                            <span style={{ fontSize: '14px' }}>No NPI License Found</span>
                                        </div>
                                        {/* <div>
                                            <button onClick={() => handleLicenseSubmission(p.doctor_licenses[index])} className="header-button" disabled={!licenseVerificationEnabled || awaitingSubmission}>
                                                Find/Verify License
                                            </button>
                                        </div> */}
                                    </div>
                                ) : (
                                    p.doctor_licenses.map((license, index) => (
                                        <div className="doctor-licenses-details" key={index}>
                                            <div className="items">
                                                <span><b>License Name:</b> {license.name}</span><br />
                                                <span><b>NPI ID:</b> {license.npi_id}</span><br />
                                                <span><b>License ID:</b> {license.license_number}</span><br />
                                                <span><b>State:</b> {license.license_number_state_code}</span><br />
                                                <span><b>Taxonomy:</b> <a href="#" onClick={() => window.open(`https://npidb.org/taxonomy/${license.license_taxonomy_code}/`, '_blank')}>{license.license_taxonomy_code}</a></span><br />
                                                <br></br>
                                                <a href="#" onClick={() => window.open(`${appConfig.signoutUri}regulatory-search/medical/?state=${convertStateAbbrToFullName(license.license_number_state_code)}&use_case=Professional+Licensing`, '_blank')}>
                                                    View {license.license_number_state_code} Medical Licensing
                                                </a>
                                                <br></br>
                                                <a href="#" onClick={() => window.open(`${appConfig.signoutUri}regulatory-search/all/?use_case=Professional+Licensing`, '_blank')}>
                                                    View All State Professional Licensing Searches
                                                </a>
                                            </div>
                                            <div className="blur-text-container">
                                                <div className={`blur-container ${!licenseVerificationEnabled ? 'blurred' : ''}`}>
                                                    <div className="items">
                                                        {license?.verifications?.length === 0 ? (
                                                            <>
                                                                <span><b>Verification Status:</b> Unverified</span><br />
                                                                <span><b>Verification Date:</b> N/A</span><br />
                                                                <span><b>Order Status:</b> N/A</span><br />
                                                                <span><b>License Status:</b> N/A</span><br />
                                                                <span><b>License Authority Board:</b> N/A</span><br />
                                                                <span><b>License Expiration:</b> N/A</span><br />
                                                                <span><b>License Category:</b> N/A</span><br />
                                                                <span><b>License Issue Date:</b> N/A</span><br />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span><b>Verification Status:</b> {license?.verifications[0]?.mesh_order_status}</span><br />
                                                                <span><b>Verification Date:</b> {license?.verifications[0]?.updated_at}</span><br />
                                                                <span><b>Order Status:</b> {license?.verifications[0]?.mesh_item_status}</span><br />
                                                                <span><b>License Status:</b> {license?.verifications[0]?.mesh_license_status}</span><br />
                                                                <span><b>License Authority Board:</b> {license?.verifications[0]?.mesh_license_authority_board}</span><br />
                                                                <span><b>License Expiration:</b> {license?.verifications[0]?.mesh_license_exp_date}</span><br />
                                                                <span><b>License Category:</b> {license?.verifications[0]?.mesh_licensee_category}</span><br />
                                                                <span><b>License Issue Date:</b> {license?.verifications[0]?.mesh_license_issue_date}</span><br />
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                {!licenseVerificationEnabled && <div className="overlay-text">Reach Out to Enable Verification Details</div>}
                                            </div>
                                            <div>
                                                <button onClick={() => handleLicenseSubmission(p.doctor_licenses[index])} className="header-button" disabled={!licenseVerificationEnabled || awaitingSubmission || !license?.license_taxonomy_code?.startsWith('1223')}>
                                                    Verify License
                                                </button>
                                                {!license?.license_taxonomy_code?.startsWith('1223') && (
                                                    <p>Cannot Verify This License</p>
                                                )}
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        )}
                        {reviewActive.includes(p.id) && (
                            <div className="doctor-review-details">
                                <table border="1" className="ratings-table">
                                    <thead>
                                        <tr>
                                            <th className='right-border'>Source</th>
                                            <th>Rating</th>
                                            <th>Total Ratings</th>
                                            <th>Total Reviews</th>
                                            <th>Reviews with Alert Words</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {p.review_summary?.map((review, index) => (
                                            <>
                                                <tr key={`${p.id}_${p.alias}`}>
                                                    <td className="right-border">
                                                        {p.review_summary[index].url_found === 0 
                                                            ?  <a href="#" onClick={() => window.open(serviceLineLink(p.review_summary[index].alias, p.name), '_blank')}>
                                                                    {p.review_summary[index].name} Reviews
                                                                </a>
                                                            :   <a href="#" onClick={() => window.open(p.review_summary[index].source_url, '_blank')}>
                                                                    {p.review_summary[index].name} Reviews
                                                                </a>
                                                        }
                                                    </td>
                                                    <td>
                                                        {p.review_summary[index].url_found === 0 
                                                            ? "No Url Found" 
                                                            : p.review_summary[index].avg_review_rating.toFixed(2)
                                                        }
                                                    </td>
                                                    <td>{p.review_summary[index].review_count}</td>
                                                    <td>{p.review_summary[index].total_reviews}</td>
                                                    <td>{p.review_summary[index].alert_word_count}</td>
                                                    <td>
                                                        {p.review_summary[index].url_found === 1 &&
                                                            <span className='dropdown' onClick={() => expand(`${p.id}_${p.review_summary[index].alias}`)}>
                                                                Show Reviews
                                                                <i className={`fa ${isExpanded.includes(`${p.id}_${p.review_summary[index].alias}`) ? 'fa-chevron-up' : 'fa-chevron-down'}`} aria-hidden="true"/>
                                                            </span>
                                                        }
                                                    </td>
                                                </tr>
                                                {isExpanded.includes(`${p.id}_${p.review_summary[index].alias}`) && p.review_summary[index].doctor_id !== null && (
                                                    <tr>
                                                        <td colSpan="6">
                                                            <div>
                                                                <DoctorReviewDetails 
                                                                    doctorId={p.review_summary[index].doctor_id}
                                                                    service={{
                                                                        lender: p.review_summary[index].lender_id,
                                                                        lender_type: p.review_summary[index].alias,
                                                                        url: p.review_summary[index].source_url
                                                                    }}
                                                                    setRenderComplete={handleRenderComplete}/>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        ))}
                                        <tr className='top-border'>
                                            <td className="right-border"><b>Totals</b></td>
                                            <td></td> {/* No total for Rating */}
                                            <td>
                                                {p.review_summary?.reduce((total, review) => total + review.review_count, 0)}
                                            </td>
                                            <td>
                                                {p.review_summary?.reduce((total, review) => total + review.total_reviews, 0)}
                                            </td>
                                            <td>
                                                {p.review_summary?.reduce((total, review) => total + review.alert_word_count, 0)}
                                            </td>
                                            <td></td> {/* No action needed for the last column */}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                        
                        <br></br>
                    </div>
                )}
                {dedupeDoctors(newDoctors)?.length === 0 && 
                <span>No Doctor Data</span>
                }
            </div>
       </div>
    )
}